import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule, inject } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { AkitaNgRouterStoreModule } from '@datorama/akita-ng-router-store';
import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import * as Sentry from '@sentry/angular';
import { LayoutModule } from './modules/core/layout/layout.module';
import { HomeRoutingModule } from './modules/home/home-routing.module';

import { BackendModule, provideEnvironment } from '@cf/core';
import { AccountTimezoneToken, Is12hFormat } from '@cf/shared/ui/typography';
import { AccountService, TimeFormatEnum } from '@cf/temp/services';
import { provideEffects, provideEffectsManager } from '@ngneat/effects-ng';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ELang } from './i18n/lang.enum';
import { AuthDialogEffect } from './libs/auth/lib/effects';
import { RefreshTokenInterceptor } from './modules/core/interceptors';
import { DialogEffects } from './modules/routing/app-container/modals/effects/dialog.effects';
import { FooterComponent } from './modules/widgets/footer/footer.component';
import { LoaderContainerComponent } from './modules/widgets/loading/components/loader-container/loader-container.component';
import { PullToRefreshComponent } from './modules/widgets/loading/components/pull-to-refresh/pull-to-refresh.component';
import { ToastContainerComponent } from './modules/widgets/toast/toast-container/toast-container.component';

export const HttpLoaderFactory = (http: HttpClient) =>
  new TranslateHttpLoader(http, './assets/i18n/', '.json');

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    LayoutModule,
    BrowserAnimationsModule,
    NgbModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      registrationStrategy: 'registerWhenStable:30000',
    }),
    environment.production ? [] : AkitaNgDevtools.forRoot(),
    AkitaNgRouterStoreModule,
    BackendModule.forRoot({
      apiUrl: environment.apiUrl,
    }),
    TranslateModule.forRoot({
      defaultLanguage: ELang.English,
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    HomeRoutingModule,
    ToastContainerComponent,
    LoaderContainerComponent,
    FooterComponent,
    PullToRefreshComponent,
  ],
  bootstrap: [AppComponent],
  providers: [
    provideEnvironment(environment),
    provideEffectsManager({
      dispatchByDefault: false,
    }),
    provideEffects(AuthDialogEffect),
    {
      provide: Is12hFormat,
      useFactory: () => {
        const accountService = inject(AccountService);
        return () =>
          accountService.account?.time_format === TimeFormatEnum['12h'];
      },
    },
    {
      provide: AccountTimezoneToken,
      useFactory: () => {
        const accountService = inject(AccountService);
        return () => accountService.account.timezone;
      },
    },
    provideEffects(DialogEffects),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RefreshTokenInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {
        // Empty function to ensure Sentry TraceService is initialized
      },
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
})
export class AppModule {}
